.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.modalContent {
	position: relative;
	max-height: 90vh;
	aspect-ratio: 210 / 297;
	width: auto;
	background-color: white;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.closeButton {
	position: absolute;
	top: 12px;
	right: 12px;
	width: 36px;
	height: 36px;
	background: none;
	border: none;
	z-index: 9999;
	line-height: 100px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	border-radius: 50%;
	transition: background-color 0.2s;

	font-size: 20px;
	line-height: 1;
	padding: 0;
}

.closeButton:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.imageContainer {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.announcementImage {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.navButton {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 36px;
	height: 36px;
	border: none;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.4);
	color: #fff;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.3s;
	z-index: 5;
}

.navButton:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.6);
}

.navLeft {
	left: 10px;
}

.navRight {
	right: 10px;
}

.pageIndicator {
	position: absolute;
	bottom: 12px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 14px;
	color: #fff;
	background-color: rgba(0, 0, 0, 0.4);
	padding: 4px 8px;
	border-radius: 8px;
	z-index: 5;
}

@media (max-width: 480px) {
	.modalContent {
		width: 95vw;
	}
}